import React from 'react';
import * as Router from 'react-router-dom';
import {MuiThemeProvider} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {default as lightTheme, darkTheme} from './theme';
import Context, {defaultValue} from './data/context';
import Header from './components/header';
import Body from './components/body';
import Footer from './components/footer';
import ProgressBar from './components/common/progressBar';
import {useApolloNetworkStatus} from './data/apolloRoot';
import {useWidth, scrollTop} from './util';

let url = '';

function NetworkStatus() {
  const width = useWidth();
  const status = useApolloNetworkStatus();

  return (
    <ProgressBar
      show={status.numPendingQueries > 0 || status.numPendingMutations > 0}
      colour={['xs', 'sm'].includes(width) ? 'secondary' : 'primary'}
    />
  );
}

function Page(props) {
  React.useEffect(() => {
    if (props.match.url !== url) {
      url = props.match.url;
      scrollTop();
    }
  });

  return (
    <Container maxWidth={false} disableGutters>
      <Header />
      <Body {...props} />
      <Footer />
    </Container>
  );
}

class App extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.handleSetState = (d) => {
      this.setState(d);
    };
    this.state = {
      ...defaultValue,
      setState: this.handleSetState,
    };
  }

  componentDidMount() {
    this.setTheme();
    this.removeSSR();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {theme} = this.state;
    const className = document.getElementById('root').className;

    if (theme !== className) {
      this.setTheme();
    }
  }

  setTheme() {
    const {theme} = this.state;
    document.body.style.backgroundColor = theme === 'dark' ? '#303030' : '';
    const app = document.getElementById('root');
    app && app.setAttribute('class', theme);
  }

  removeSSR() {
    const styles = document.querySelector('style#jss');
    const state = document.querySelector('script#state');

    if (styles) {
      styles.parentElement.removeChild(styles);
    }
    if (state) {
      state.parentElement.removeChild(state);
    }
  }

  render() {
    return (
      <MuiThemeProvider
        theme={this.state.theme !== 'light' ? darkTheme : lightTheme}
      >
        <Context.Provider value={this.state}>
          <NetworkStatus />

          <Router.Switch>
            {[
              {path: '/index/:page/'},

              {path: '/tag/:name/:page/'},
              {path: '/tag/:name/'},

              {path: '/search/:name/:page/'},
              {path: '/search/:name/'},

              {path: '/archive/:name/:page/'},
              {path: '/archive/:name/'},

              {path: '/category/:name/:page/'},
              {path: '/category/:name/'},

              {path: '/about/'},
              {path: '/article/:url/'},
            ].map(({path}) => (
              <Router.Route
                key={path}
                path={path}
                render={(props) => <Page {...props} />}
              />
            ))}

            <Router.Redirect to='/index/1' />
          </Router.Switch>
        </Context.Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
