import {gql} from 'apollo-boost';

const ARCHIVE = `
  archives(type: DEVELOP) {
    edges {
      node {
        ... on DevelopArchives {
          name
          articleCount
        }
      }
    }
  }
`;

const ABOUT = `
  about: configs(type: DEVELOP, search: "sys_about") {
    nodes {
      ... on DevelopConfigs {
        value
      }
    }
  }
`;

const BILLBOARD = `
  billboard: configs(type: DEVELOP, search: "sys_billboard") {
    nodes {
      ... on DevelopConfigs {
        value
      }
    }
  }
`;

const LINKS = `
  links: configs(type: DEVELOP, search: "sys_links") {
    nodes {
      ... on DevelopConfigs {
        value
      }
    }
  }
`;

const CATEGORY = `
  categories(type: DEVELOP) {
    edges {
      node {
        ... on DevelopCategories {
          code
          name
        }
      }
    }
  }
`;

const TAG = `
  tags(type: DEVELOP) {
    edges {
      node {
        ... on DevelopTags {
          name
          articleCount
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  {
    ${CATEGORY}
  }
`;

export const GET_ABOUT = gql`
  {
    ${ABOUT}
  }
`;

export const GET_LINKS = gql`
  {
    ${LINKS}
  }
`;

export const GET_CACHE = gql`
  {
    ${ARCHIVE}
    ${BILLBOARD}
    ${CATEGORY}
    ${TAG}
    ${ABOUT}
    ${LINKS}
  }
`;

export const GET_ARTICLES = gql`
  query(
    $search: String
    $archive: String
    $category: String
    $tag: String
    $page: Int = 1
  ) {
    articles(
      type: DEVELOP
      archive: $archive
      category: $category
      tag: $tag
      search: $search
      page: $page
      pageSize: 7
    ) {
      totalCount
      edges {
        node {
          ... on DevelopArticles {
            id
            title
            description
            url
            createdAt
            tags {
              edges {
                node {
                  ... on DevelopTags {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ARTICLE = gql`
  query($url: String) {
    article: articles(type: DEVELOP, last: 100, url: $url) {
      nodes {
        ... on DevelopArticles {
          id
          rendered
        }
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query($article: ID) {
    comments(type: DEVELOP, subject: $article, first: 100) {
      edges {
        node {
          ... on DevelopComments {
            createdAt
            id
            name
            site
            content
            reply {
              name
              content
            }
          }
        }
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateComment(
    $articleID: ID!
    $content: String!
    $email: String!
    $name: String!
    $reply: ID
    $site: String!
    $visible: Boolean!
  ) {
    createComment(
      input: {
        subject: $articleID
        name: $name
        site: $site
        content: $content
        email: $email
        reply: $reply
        visible: $visible
      }
    ) {
      comment {
        id
        name
        content
        site
        reply {
          name
          content
        }
        createdAt
      }
    }
  }
`;
