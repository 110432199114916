import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {useWidth} from '../../util';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' {...props} ref={ref} />
));

export default function Donate({show = null, toggleShow}) {
  const classes = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    center: {
      display: 'flex',
      alignItems: 'center',
    },
    centerText: {
      flexGrow: 1,
      textAlign: 'center',
    },
    qr: {
      width: '42vw',
      maxWidth: 200,
      margin: '0 auto',
      display: 'block',
    },
  }))();
  const width = useWidth();
  const [qrCode, setQrCode] = React.useState('');

  React.useEffect(() => {
    const ua = window.navigator.userAgent || '';

    if (ua.includes('AlipayClient')) {
      setQrCode(process.env['REACT_APP_DONATE_ALIPAY'] || '');
    } else if (ua.includes('MicroMessenger')) {
      setQrCode(process.env['REACT_APP_DONATE_WECHAT'] || '');
    } else if (ua.includes('QQ')) {
      setQrCode(process.env['REACT_APP_DONATE_QQ'] || '');
    } else {
      setQrCode(process.env['REACT_APP_DONATE_INDEX'] || '');
    }
  }, []);

  return show !== null ? (
    <Dialog
      open={show}
      fullScreen={['xs', 'sm'].includes(width)}
      disableBackdropClick
      TransitionComponent={Transition}
      transitionDuration={{
        enter: 350,
        exit: 250,
      }}
      onClose={toggleShow}
    >
      <DialogTitle>支持我</DialogTitle>
      <DialogContent className={classes.center}>
        <Grid container spacing={3}>
          <Grid item xs={12} key='a'>
            <img className={classes.qr} src={qrCode} alt='' />
          </Grid>
          <Grid item xs={12} key='b'>
            <Typography
              paragraph
              variant='body1'
              color='textSecondary'
              className={classes.centerText}
            >
              支付宝 / 微信 / QQ
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShow}>关闭</Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='h6' className={classes.heading}>
          支持我
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} key='a'>
            <img className={classes.qr} src={qrCode} alt='' />
          </Grid>
          <Grid item xs={12} key='b'>
            <Typography
              paragraph
              variant='body1'
              color='textSecondary'
              className={classes.centerText}
            >
              支付宝 / 微信 / QQ
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
