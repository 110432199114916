import {createMuiTheme} from '@material-ui/core/styles';

const primaryColor = '#006699';

const primaryColorLight = '#0088cc';

const secondaryColor = '#d81b60';

const footerColor = '#cfd8dc';

const footerBackground = '#303c42';

const textColor = '#212121';

const light = {
  palette: {
    type: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Microsoft YaHei Light"',
      '"Microsoft YaHei"',
    ].join(','),
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: primaryColorLight,
        },
      },
    },
  },
};

const dark = Object.assign({}, light, {
  palette: {
    ...light.palette,
    type: 'dark',
  },
});

const theme = createMuiTheme(light);

const darkTheme = createMuiTheme(dark);

export {
  primaryColor,
  primaryColorLight,
  secondaryColor,
  footerColor,
  footerBackground,
  textColor,
  darkTheme,
};

export default theme;
