import fetch from 'node-fetch';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createHttpLink} from 'apollo-link-http';
import {createNetworkStatusNotifier} from 'react-apollo-network-status';

import {defaults, resolvers, typeDefs} from './resolvers';

const {link, useApolloNetworkStatus} = createNetworkStatusNotifier();

const client = new ApolloClient({
  clientState: {
    defaults,
    resolvers,
    typeDefs,
  },
  link: link.concat(
    createHttpLink({uri: 'https://api.pulsgarney.com/graphql/', fetch}),
  ),
  // eslint-disable-next-line no-undef
  cache: new InMemoryCache().restore(globalThis['__APOLLO_STATE__'] || {}),
});

export default client;
export {useApolloNetworkStatus};
