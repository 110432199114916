import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const compose = (...fns) => {
  return fns.reduce(
    (prevFn, nextFn) => {
      return (value) => nextFn(prevFn(value));
    },
    (value) => value,
  );
};

export const parseTime = (time) => {
  const t = new Date(time);
  const p = (x) => `00${x}`.slice(-2);
  return [
    `${t.getFullYear()}-${p(t.getMonth() + 1)}-${p(t.getDate())}`,
    `${p(t.getHours())}:${p(t.getMinutes())}`,
  ].join(' ');
};

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export const useWidth = function () {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export const scrollTop = () => {
  window.scrollBy(0, -17);
  if (document.body.scrollTop || document.documentElement.scrollTop > 10)
    setTimeout(scrollTop, 5);
};
