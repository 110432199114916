import React from 'react';
import {Query} from 'react-apollo';
import {Link, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {GET_CACHE} from '../../data/sql';

const Search = withRouter(({history}) => {
  const classes = makeStyles(() => ({
    paper: {
      margin: '0 12px 24px',
      padding: 24,
    },
  }))();
  const [keyword, setKeyword] = React.useState('');

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      history.push(`/search/${keyword}`);
      setKeyword('');
    }
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <Paper elevation={0} className={classes.paper}>
      <TextField
        fullWidth
        label='搜索：'
        id='search'
        value={keyword}
        onChange={handleChange}
        onKeyPress={handleSubmit}
        inputProps={{
          autoComplete: 'off',
          maxLength: 32,
        }}
      />
    </Paper>
  );
});

function Billboard({billboard: {nodes: [{value = ''} = {}] = []} = {}}) {
  const classes = makeStyles(() => ({
    paper: {
      margin: '0 12px 24px',
      padding: 24,
    },
  }))();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography gutterBottom variant='h6' children='公告：' />
      <Typography
        variant='subtitle1'
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    </Paper>
  );
}

function Tag({tags: {edges = []} = {}}) {
  const classes = makeStyles(() => ({
    paper: {
      margin: '0 12px 24px',
      padding: 24,
    },
    link: {
      padding: 4,
      display: 'inline-block',
    },
  }))();

  const minFont = 10;
  const maxFont = 30;
  const sizeMap = {};
  const tagTable = {};

  edges.map(({node = {}}) => {
    if (!tagTable[node['articleCount'] || 0]) {
      tagTable[node['articleCount'] || 0] = [];
    }
    tagTable[node['articleCount'] || 0].push({
      name: node['name'],
      count: node['articleCount'],
    });
    return 1;
  });

  Object.keys(tagTable).map((x, index) => {
    sizeMap[x] = index + 1;
    return 1;
  });

  const level = Object.keys(tagTable).length - 1;
  const tags = edges
    .map(({node = {}}) => node)
    .map((x) => ({
      ...x,
      size: ((maxFont - minFont) / level) * sizeMap[x['articleCount']],
    }));

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography gutterBottom variant='h6' children='标签：' />
      {
        <div>
          {tags.map((item) => (
            <Link to={`/tag/${item.name}`} key={item.name}>
              <Tooltip
                placement='bottom'
                title={`${item.name} (${item.articleCount})`}
              >
                <Typography
                  variant='body1'
                  children={item.name}
                  className={classes.link}
                  style={{fontSize: item.size}}
                />
              </Tooltip>
            </Link>
          ))}
        </div>
      }
    </Paper>
  );
}

function Archive({archives: {edges = []} = {}}) {
  const classes = makeStyles(() => ({
    paper: {
      margin: '0 12px 24px',
      padding: 24,
    },
    link: {
      paddingTop: 4,
      paddingBottom: 4,
    },
  }))();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography gutterBottom variant='h6' children='归档：' />
      <List disablePadding>
        {edges
          .map(({node = {}}) => node)
          .map((item) => (
            <Link key={item.name} to={`/archive/${item.name}`}>
              <ListItem classes={{root: classes.link}}>
                <Tooltip
                  placement='bottom-start'
                  title={`${item.name} (${item.articleCount})`}
                >
                  <ListItemText
                    primary={`${item.name}(${item.articleCount})`}
                  />
                </Tooltip>
              </ListItem>
            </Link>
          ))}
      </List>
    </Paper>
  );
}

export default function Aside() {
  return (
    <Query query={GET_CACHE}>
      {({data: {archives, billboard, tags} = {}}) => {
        return (
          <React.Fragment>
            <Search />
            <Billboard billboard={billboard} />
            <Tag tags={tags} />
            <Archive archives={archives} />
          </React.Fragment>
        );
      }}
    </Query>
  );
}
