import React from 'react';
import {Query} from 'react-apollo';
import {NavLink, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Collapse from '@material-ui/core/Collapse';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CategoryRounded from '@material-ui/icons/CategoryRounded';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FaceRounded from '@material-ui/icons/FaceRounded';
import HomeRounded from '@material-ui/icons/HomeRounded';
import WbSunny from '@material-ui/icons/WbSunny';
import Brightness2 from '@material-ui/icons/Brightness2';
import Close from '@material-ui/icons/Close';
import Menu from '@material-ui/icons/Menu';
import SearchIcons from '@material-ui/icons/Search';
import Context from '../../data/context';
import {GET_CATEGORY} from '../../data/sql';
import {primaryColor, textColor} from '../../theme';

function Title() {
  const classes = makeStyles((theme) => ({
    background: {
      padding: '24px',
      textAlign: 'center',
    },
    title: {
      fontSize: theme.typography.pxToRem(42),
      color: textColor,
      fontWeight: 300,
    },
    subTitle: {
      marginTop: '12px',
      fontSize: theme.typography.pxToRem(18),
      color: textColor,
      fontWeight: 300,
    },
  }))();

  return (
    <Container maxWidth={false} disableGutters>
      <Paper className={classes.background} elevation={0} square>
        <Typography
          variant='h3'
          classes={{h3: classes.title}}
          children={'兵團基地'}
        />
        <Typography
          variant='h6'
          classes={{h6: classes.subTitle}}
          children={'一起學習、共同進步。'}
        />
      </Paper>
    </Container>
  );
}

function Navigation() {
  const classes = makeStyles((theme) => ({
    toolbar: {
      minHeight: '56px',
      justifyContent: 'center',
    },
    buttonRoot: {
      height: '56px',
      minWidth: '112px',
      color: '#eee',
      fontSize: theme.typography.pxToRem(21),
      fontWeight: 400,
    },
    buttonMusic: {
      color: primaryColor,
      background: '#fff',
      borderRadius: 0,
      '&:hover': {
        color: primaryColor,
        background: '#fff',
        borderRadius: 0,
      },
    },
    buttonMenu: {
      minWidth: '112px',
      justifyContent: 'center',
    },
  }))();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  return (
    <AppBar position='static'>
      <Toolbar disableGutters classes={{root: classes.toolbar}}>
        <NavLink to='/index/1/'>
          <Button classes={{root: classes.buttonRoot}}>首页</Button>
        </NavLink>
        <Button
          ref={anchorRef}
          classes={{root: classes.buttonRoot}}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
        >
          随记杂谈
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement='bottom-start'
          >
            {({TransitionProps}) => (
              <Grow in={open} {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList>
                      <Query query={GET_CATEGORY} fetchPolicy='cache-only'>
                        {({data: {categories: {edges = []} = {}} = {}}) => {
                          return edges
                            .map(({node = {}}) => node)
                            .map((c) => (
                              <NavLink key={c.code} to={`/category/${c.code}`}>
                                <MenuItem
                                  className={classes.buttonMenu}
                                  children={c.name}
                                />
                              </NavLink>
                            ));
                        }}
                      </Query>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Button>
        <Button
          target='_blank'
          href='https://music.pulsgarney.com/'
          className={classes.buttonMusic}
          classes={{root: classes.buttonRoot}}
          rel={'noopener noreferrer'}
        >
          音樂
        </Button>
        <NavLink to='/about/'>
          <Button classes={{root: classes.buttonRoot}}>关于我</Button>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
}

function Search({show, toggleSearch, history}) {
  const classes = makeStyles((theme) => ({
    input: {
      height: '100%',
      width: '100%',
      margin: 0,
      border: 0,
      padding: '0 0 0 16px',
      fontSize: theme.typography.pxToRem(21),
      outline: 'none',
      fontWeight: 300,
      fontFamily: `Roboto, "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei Light", "Microsoft YaHei", SimSun, Arial, sans-serif`,
    },
  }))();
  const [keyword, setKeyword] = React.useState('');

  const handleSubmit = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      history.push(`/search/${keyword}`);
      setKeyword('');
      toggleSearch();
    }
  };

  const handleChange = (e) => {
    setKeyword(e.target.value);
  };

  return (
    <Fade in={show} addEndListener={null}>
      <Toolbar style={{backgroundColor: 'white'}}>
        <input
          placeholder={'搜索'}
          className={classes.input}
          onChange={handleChange}
          onKeyPress={handleSubmit}
          autoComplete='off'
          maxLength={32}
        />
        <IconButton color='default' onClick={toggleSearch}>
          <Close style={{color: 'rgba(0, 0, 0, 0.54)'}} />
        </IconButton>
      </Toolbar>
    </Fade>
  );
}

function Bar({toggleDrawer, toggleSearch}) {
  const classes = makeStyles(() => ({
    flex: {
      marginLeft: 8,
      flexGrow: 1,
      userSelect: 'none',
      fontWeight: 400,
    },
  }))();

  return (
    <Toolbar>
      <IconButton color='inherit' onClick={toggleDrawer}>
        <Menu />
      </IconButton>
      <Typography variant='h6' color='inherit' className={classes.flex}>
        兵團基地
      </Typography>
      <Context.Consumer>
        {({theme, setState = () => {}}) => {
          const handleTheme = () => {
            setState(({theme}) => ({
              theme: {light: 'dark', dark: 'light'}[theme],
            }));
          };

          return (
            <IconButton color='inherit' onClick={handleTheme}>
              {theme === 'light' ? <Brightness2 /> : <WbSunny />}
            </IconButton>
          );
        }}
      </Context.Consumer>
      <IconButton color='inherit' onClick={toggleSearch}>
        <SearchIcons />
      </IconButton>
    </Toolbar>
  );
}

function Drawer({show, toggleDrawer}) {
  const classes = makeStyles((theme) => ({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }))();
  const [open, setOpen] = React.useState(true);

  return (
    <SwipeableDrawer
      open={show}
      onClose={toggleDrawer}
      onOpen={toggleDrawer}
      PaperProps={{
        style: {width: '70%'},
      }}
    >
      <List>
        <NavLink to='/index/1'>
          <ListItem button onClick={toggleDrawer}>
            <ListItemIcon>
              <HomeRounded />
            </ListItemIcon>
            <ListItemText primary='首页' />
          </ListItem>
        </NavLink>

        <ListItem button onClick={() => setOpen((prevState) => !prevState)}>
          <ListItemIcon>
            <CategoryRounded />
          </ListItemIcon>
          <ListItemText primary='随记杂谈' />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} addEndListener={null}>
          <List onClick={toggleDrawer}>
            <Query query={GET_CATEGORY} fetchPolicy='cache-only'>
              {({data: {categories: {edges = []} = {}} = {}}) =>
                edges
                  .map(({node = {}}) => node)
                  .map((c) => (
                    <NavLink key={c.code} to={`/category/${c.code}`}>
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <FaceRounded style={{color: 'transparent'}} />
                        </ListItemIcon>
                        <ListItemText primary={c.name} />
                      </ListItem>
                    </NavLink>
                  ))
              }
            </Query>
          </List>
        </Collapse>

        <NavLink to='/about'>
          <ListItem button onClick={toggleDrawer}>
            <ListItemIcon>
              <FaceRounded />
            </ListItemIcon>
            <ListItemText primary='关于我' />
          </ListItem>
        </NavLink>
      </List>
    </SwipeableDrawer>
  );
}

function Header(props) {
  const {history} = props;
  const [showSearch, setShowSearch] = React.useState(false);
  const [showDrawer, setShowDrawer] = React.useState(false);
  const toggleDrawer = () => setShowDrawer((prevState) => !prevState);
  const toggleSearch = () => setShowSearch((prevState) => !prevState);

  return (
    <Container maxWidth={false} disableGutters>
      <Hidden smDown implementation={'css'}>
        <Title />
        <Navigation />
      </Hidden>
      <Hidden mdUp implementation={'css'}>
        <AppBar color='primary'>
          {showSearch ? (
            <Search
              show={showSearch}
              toggleSearch={toggleSearch}
              history={history}
            />
          ) : (
            <Bar toggleDrawer={toggleDrawer} toggleSearch={toggleSearch} />
          )}
        </AppBar>
        <div style={{minHeight: 64}} />
        <Drawer show={showDrawer} toggleDrawer={toggleDrawer} />
      </Hidden>
    </Container>
  );
}

export default withRouter(Header);
