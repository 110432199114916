import React from 'react';
import classNames from 'classnames';
import {Mutation, Query} from 'react-apollo';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Reply from '@material-ui/icons/Reply';
import Home from '@material-ui/icons/Home';
import {parseTime, useWidth} from '../../util';
import {primaryColor} from '../../theme';
import Context from '../../data/context';
import {CREATE_COMMENT, GET_COMMENTS} from '../../data/sql';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction='up' {...props} ref={ref} />
));

function Form({show, reply, replyName, toggleShow, article}) {
  const classes = makeStyles((theme) => ({
    snackbar: {
      [theme.breakpoints.down('sm')]: {
        bottom: 56,
      },
    },
  }))();
  const width = useWidth();
  const [snackbar, setSnackbar] = React.useState(null);
  const [state, setState] = React.useState({
    name: '',
    site: '',
    email: '',
    content: '',
    visible: false,
    errorTips: {},
  });
  const {name, site, email, content, visible, errorTips} = state;

  const handleClose = () => {
    setState({
      name: '',
      site: '',
      email: '',
      content: '',
      visible: false,
      errorTips: {},
    });
    toggleShow();
  };

  const handleChange = (name) => (e) => {
    setState({...state, [name]: e.target.value});
  };

  const handleSwitch = (e) => {
    setState({...state, visible: e.target.checked});
  };

  const handleSubmit = (fn) => {
    const {name, site, email, content, visible} = state;
    const errorTips = {
      name: name.trim().length < 1 ? '名字呢ψ(._. )>' : '',
      email:
        email.trim().length < 1 || !/^\S+@\S+\.\w+$/.test(email.trim())
          ? '这里要填邮箱地址噢(°°)～'
          : '',
      content: content.trim().length < 1 ? "内容也要填的噢(●'◡'●)" : '',
    };

    setState({...state, errorTips});

    if (Object.values(errorTips).some((x) => x.length)) return;

    fn &&
      fn({
        variables: {
          articleID: article,
          content,
          email,
          name,
          reply,
          site,
          visible: !visible,
        },
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={show}
        fullScreen={['xs', 'sm'].includes(width)}
        disableBackdropClick
        TransitionComponent={Transition}
        transitionDuration={{
          enter: 350,
          exit: 250,
        }}
        onClose={handleClose}
      >
        <DialogTitle>
          {replyName ? `新评论 @${replyName}` : '新评论'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label='名字'
                required
                fullWidth
                placeholder='您的尊姓大名'
                value={name}
                error={Boolean(errorTips.name)}
                helperText={errorTips.name || ''}
                onChange={handleChange('name')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label='邮箱'
                required
                fullWidth
                placeholder='一个我可以找到您的联系方式'
                value={email}
                error={Boolean(errorTips.email)}
                helperText={errorTips.email || ''}
                onChange={handleChange('email')}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label='内容'
                required
                fullWidth
                multiline
                placeholder='内容'
                rows={6}
                value={content}
                error={Boolean(errorTips.content)}
                helperText={errorTips.content || ''}
                onChange={handleChange('content')}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label='个人站点'
                fullWidth
                placeholder='您的个人主页或博客站点'
                value={site}
                onChange={handleChange('site')}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormGroup row>
                <FormControlLabel
                  label={visible ? '仅博主可见' : '所有人可见'}
                  control={
                    <Switch
                      checked={visible}
                      onChange={handleSwitch}
                      color='primary'
                    />
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Query
            query={GET_COMMENTS}
            variables={{article}}
            fetchPolicy='cache-only'
          >
            {({data: {comments: {edges = []} = {}} = {}, client}) => (
              <Mutation
                mutation={CREATE_COMMENT}
                onError={(error) => {
                  setSnackbar(error.message);
                }}
                update={(_, {data: {createComment}}) => {
                  handleClose();
                  setSnackbar('发布成功');

                  if (edges && edges.length) {
                    client.writeQuery({
                      query: GET_COMMENTS,
                      variables: {article},
                      data: {
                        comments: {
                          edges: [
                            {
                              node: {
                                id: createComment.comment.id,
                                name: createComment.comment.name,
                                content: createComment.comment.content,
                                site: createComment.comment.site,
                                reply:
                                  createComment.comment['reply'] &&
                                  createComment.comment['reply']['name']
                                    ? {
                                        name:
                                          createComment.comment['reply'][
                                            'name'
                                          ],
                                        content:
                                          createComment.comment['reply'][
                                            'content'
                                          ],
                                        __typename: 'DevelopComments',
                                      }
                                    : null,
                                createdAt: createComment.comment.createdAt,
                                __typename: 'DevelopComments',
                              },
                              __typename: 'CommentEdge',
                            },
                            ...edges,
                          ],
                          __typename: 'CommentConnection',
                        },
                      },
                    });
                  }
                }}
              >
                {(createComment) => (
                  <Button
                    color='primary'
                    onClick={() => handleSubmit(createComment)}
                  >
                    保存
                  </Button>
                )}
              </Mutation>
            )}
          </Query>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={Boolean(snackbar)}
        onClose={() => setSnackbar(null)}
        message={snackbar}
        TransitionComponent={Transition}
        className={classes.snackbar}
      />
    </React.Fragment>
  );
}

export default function Comments({article}) {
  const classes = makeStyles((theme) => ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    center: {
      textAlign: 'center',
      marginBottom: '2rem',

      '& h6': {
        marginTop: '1rem',
      },
    },
    card: {
      width: '100%',
    },
    re: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      paddingLeft: '1rem',
      borderLeft: '4px solid #ccc',
    },
    indent: {
      paddingLeft: '2rem',
    },
    avatar: {
      backgroundColor: primaryColor,
    },
    centerText: {
      flexGrow: 1,
      textAlign: 'center',
    },
  }))();
  const [ID, setID] = React.useState(null);
  const [name, setName] = React.useState('');
  const [show, setShow] = React.useState(false);

  const toggleShow = (replyName = '', replyId = null) => {
    setID(replyId);
    setName(replyName);
    setShow((prevState) => !prevState);
  };

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='h6' className={classes.heading}>
          评论
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12} key='a'>
            <Button fullWidth onClick={() => toggleShow()}>
              我来索两句
            </Button>
          </Grid>
          <Query query={GET_COMMENTS} variables={{article}}>
            {({data: {comments: {edges = []} = {}} = {}}) => {
              const comments = edges.map(({node}) => node);

              return comments.length <= 0 ? (
                <Grid item xs={12} key='b'>
                  <Typography
                    paragraph
                    variant='body1'
                    color='textSecondary'
                    className={classes.centerText}
                  >
                    暂无评论
                  </Typography>
                </Grid>
              ) : (
                comments.map(
                  ({id, name, reply, site, createdAt, content}, index) => {
                    return (
                      <Grid item xs={12} key={id}>
                        <Card elevation={0} className={classes.card}>
                          <CardHeader
                            title={name}
                            subheader={parseTime(createdAt)}
                            avatar={
                              <Context.Consumer>
                                {({theme}) => (
                                  <Avatar
                                    className={
                                      theme === 'light' ? classes.avatar : ''
                                    }
                                  >
                                    {String(name[0]).toUpperCase()}
                                  </Avatar>
                                )}
                              </Context.Consumer>
                            }
                            action={
                              <React.Fragment>
                                {site && (
                                  <IconButton
                                    color='primary'
                                    onClick={() => {
                                      window.open(
                                        /http/.test(site)
                                          ? site
                                          : `https://${site}`,
                                        null,
                                        'noopener,noreferrer',
                                      );
                                    }}
                                  >
                                    <Home />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() => toggleShow(name, id)}
                                >
                                  <Reply />
                                </IconButton>
                              </React.Fragment>
                            }
                          />
                          <CardContent>
                            {reply && reply.name && (
                              <React.Fragment>
                                <Typography
                                  variant='subtitle1'
                                  color='textSecondary'
                                  className={classes.re}
                                >
                                  {`@${reply.name}`}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  paragraph
                                  variant='subtitle1'
                                  color='textSecondary'
                                  className={classNames(
                                    classes.re,
                                    classes.indent,
                                  )}
                                >
                                  {reply.content}
                                </Typography>
                              </React.Fragment>
                            )}
                            <Typography variant='body1' paragraph>
                              {content}
                            </Typography>
                          </CardContent>
                        </Card>
                        {index + 1 !== comments.length && <Divider />}
                      </Grid>
                    );
                  },
                )
              );
            }}
          </Query>
        </Grid>
      </AccordionDetails>
      <Form
        show={show}
        reply={ID}
        replyName={name}
        toggleShow={toggleShow}
        article={article}
      />
    </Accordion>
  );
}
