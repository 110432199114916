import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = () => ({
  root: {
    width: '100%',
    // height: 3,
    flexGrow: 1,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    zIndex: 10000000,
  },
});

class ProgressBar extends React.Component {
  timer = null;

  state = {
    completed: 0,
    buffer: 10,
  };

  componentDidMount() {
    this.timer = setInterval(this.progress, 100);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /* eslint-disable no-invalid-this */
  reset = () => {
    this.setState({
      completed: 0,
      buffer: 0,
    });
  };

  progress = () => {
    const {show} = this.props;
    const {completed, buffer} = this.state;
    const ram = Math.random();
    const ram2 = Math.random();

    if (show)
      if (completed > 75) {
        if (completed > 97) this.setState({completed});
        else if (buffer > 90) this.setState({completed: completed + ram2});
        else
          this.setState({
            buffer: buffer + ram,
            completed: completed + ram2,
          });
      } else if (completed > 45)
        this.setState({
          buffer: completed + ram * 10 + ram2 * 10,
          completed: completed + ram * 10,
        });
      else
        this.setState({
          buffer: completed + ram * 30 + ram2 * 30,
          completed: completed + ram * 30,
        });
    else this.reset();
  };

  render() {
    const {classes, show, colour} = this.props;
    const {completed, buffer} = this.state;

    return (
      <div className={classes.root}>
        {show && (
          <LinearProgress
            color={colour || 'primary'}
            variant='buffer'
            value={completed}
            valueBuffer={buffer}
          />
        )}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  colour: PropTypes.string.isRequired,
};

export default withStyles(styles)(ProgressBar);
