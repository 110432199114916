import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

/* Available parameters are (allPage, currentPage, itemPerPage, linkPrefix,) */
/* For the 'linkPrefix' param please use a string like '/index/<PAGE>/' */
/* It will be turn into something like '/index/1/' for the page 1 and etc. */

const getPageNum = (
  all = '1',
  current = '1',
  perPage = '5',
  prefixStr = '',
) => {
  all = Number(all);
  current = Number(current);
  perPage = Number(perPage);

  if (all < perPage) perPage = all;
  let _page = Array(perPage).fill({});

  // If just one page in total, do not display any buttons
  if (all === 1) return [];

  let beginNum = current - Math.ceil(perPage / 2) + 1;
  let span = perPage - 1;

  for (let i of [..._page]) {
    _page[_page.indexOf(i)] = {name: String(beginNum)};
    beginNum += span;
    _page.indexOf(i) % 2 === 0 ? (span = -(span + 1)) : (span = -(span - 1));
  }

  for (let i of [..._page]) {
    let _num = Number(i.name);
    // Page is greater than the Final one
    if (all - _num < 0) _num -= perPage;
    // Page is smaller than the 1
    else if (_num - 1 < 0) _num += perPage;
    _page[_page.indexOf(i)] = {
      name: String(_num),
      link: prefixStr.replace('<PAGE>', _num),
    };
  }

  _page = _page.sort((a, b) => a.name - b.name);

  if (Number(_page[0].name) !== current)
    _page.splice(0, 0, {
      name: '<',
      link: prefixStr.replace('<PAGE>', String(current - 1)),
    });
  if (Number(_page[_page.length - 1].name) !== current)
    _page.splice(_page.length, 0, {
      name: '>',
      link: prefixStr.replace('<PAGE>', String(current + 1)),
    });

  return _page;
};

export default function Paginator({
  allPage,
  currentPage = 1,
  itemPerPage = 5,
  linkPrefix,
}) {
  const classes = makeStyles(() => ({
    box: {
      margin: '0 12px 24px',
      padding: '8px 0 32px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      minWidth: '36px',
      height: '48px',
      color: '#0088cc',
    },
    paper: {
      margin: '0 4px',
      display: 'inline-block',
    },
    active: {
      color: '#ffffff',
      backgroundColor: '#109be0',
      '&:hover': {
        color: '#ffffff',
        backgroundColor: '#109be0',
      },
    },
  }))();

  return (
    <div className={classes.box}>
      {allPage && linkPrefix
        ? getPageNum(allPage, currentPage, itemPerPage, linkPrefix).map(
            (item) => (
              <Link
                to={item.link}
                key={item.name}
                style={{display: 'inline-flex'}}
                children={
                  <Paper
                    key={item.name}
                    elevation={0}
                    className={classes.paper}
                  >
                    <Button
                      type='flat'
                      href='##'
                      component='span'
                      children={item.name}
                      className={classNames(classes.button, {
                        [classes.active]: item.name === String(currentPage),
                      })}
                    />
                  </Paper>
                }
              />
            ),
          )
        : null}
    </div>
  );
}
