import React from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider} from 'react-apollo';
import {BrowserRouter} from 'react-router-dom';
import {
  createGenerateClassName,
  StylesProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import client from './data/apolloRoot';
import App from './App';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

// Do not use hydrate, style crashes.
ReactDOM.render(
  <div className='App'>
    <ApolloProvider client={client}>
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <CssBaseline />
          <App />
        </BrowserRouter>
      </StylesProvider>
    </ApolloProvider>
  </div>,
  document.getElementById('root'),
);
