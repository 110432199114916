import React from 'react';
import {Link} from 'react-router-dom';
import {Query, graphql, withApollo} from 'react-apollo';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import NoSsr from '@material-ui/core/NoSsr';
import {compose, parseTime, useWidth} from '../../util';
import Paginator from '../common/paginator';
import {primaryColorLight} from '../../theme';
import Aside from './aside';
import Donate from './donate';
import Comment from './comment';
import {GET_CACHE, GET_ARTICLES, GET_ABOUT, GET_ARTICLE} from '../../data/sql';

function Bar({text}) {
  const classes = makeStyles(() => ({
    body: {
      margin: '0 12px 24px',
    },
    text: {
      padding: 24,
    },
  }))();

  return (
    text && (
      <Grid item>
        <Paper elevation={0} className={classes.body}>
          <Typography variant='h5' children={text} className={classes.text} />
        </Paper>
      </Grid>
    )
  );
}

function Card({article: {url, title, time, description, tags}}) {
  const classes = makeStyles(() => ({
    card: {
      margin: '0 12px 24px',
      padding: '24px 24px 16px',
    },
    title: {
      color: primaryColorLight,
      fontWeight: 300,
      cursor: 'pointer',
    },
    time: {
      margin: '16px 24px',
      color: '#777',
      fontWeight: 300,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    body: {
      margin: '16px 8px',
    },
    tagBox: {
      paddingTop: 8,
    },
    hr: {
      marginBottom: 16,
    },
    tag: {
      padding: '0 4px',
    },
    chip: {
      height: 24,
    },
    label: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  }))();

  return (
    <Paper elevation={0} className={classes.card}>
      <Hidden smDown implementation={'css'}>
        <Link to={`/article/${url}/`}>
          <Typography
            variant={'h4'}
            children={title}
            className={classes.title}
          />
        </Link>
      </Hidden>
      <Hidden mdUp implementation={'css'}>
        <Link to={`/article/${url}/`}>
          <Typography
            variant={'h5'}
            children={title}
            className={classes.title}
          />
        </Link>
      </Hidden>
      <Typography variant='subtitle1' className={classes.time}>
        {`# ${parseTime(time)}`}
      </Typography>
      <Typography
        variant='body1'
        className={classes.body}
        dangerouslySetInnerHTML={{__html: description}}
      />
      <div className={classes.tagBox}>
        <Divider light className={classes.hr} />
        {tags &&
          tags.map((item) => (
            <Link key={item} className={classes.tag} to={`/tag/${item}`}>
              <Chip
                clickable
                label={item}
                variant='outlined'
                classes={{
                  root: classes.chip,
                  label: classes.label,
                }}
              />
            </Link>
          ))}
      </div>
    </Paper>
  );
}

function Sections({toolBar = '', articles, page}) {
  return (
    <Grid container spacing={0}>
      <Grid item xs>
        <Bar text={toolBar} />
        {articles &&
          articles.map((item) => <Card article={item} key={item.id} />)}
        {page && <Paginator {...page} />}
      </Grid>
    </Grid>
  );
}

function Body(props) {
  const classes = makeStyles(() => ({
    body: {
      minHeight: 600,
      maxWidth: 1200,
      margin: '24px auto',
    },
    paper: {
      minHeight: 600,
      margin: '0 24px',
      padding: 24,
    },
  }))();
  const {
    data: {categories: {edges = []} = {}} = {},
    match: {
      path = '',
      params: {name, page, url},
    },
  } = props;
  const category = edges.map(({node}) => node);
  const [, comp = 'index'] = path.split('/');
  const width = useWidth();
  const _page = Number(page) || 1;
  const _name = decodeURIComponent(name);
  const base = '兵團基地 | 一起學習、共同進步 /0。0、。';
  let title = base;
  let toolBar = '';
  let variables = {};
  let pagePrefix = '<PAGE>';

  switch (comp) {
    case 'index':
      toolBar = '';
      variables = {page: _page};
      pagePrefix = '/index/<PAGE>/';
      title = `首页 | ${base}`;
      break;

    case 'archive':
      toolBar = `归档：${_name}`;
      variables = {page: _page, archive: _name};
      pagePrefix = `/archive/${_name}/<PAGE>/`;
      title = `归档 | ${_name} | ${base}`;
      break;

    case 'tag':
      toolBar = `标签：${_name}`;
      variables = {page: _page, tag: _name};
      pagePrefix = `/tag/${_name}/<PAGE>/`;
      title = `标签 | ${_name} | ${base}`;
      break;

    case 'category':
      const obj = category.find((x) => x.code === _name) || {};
      toolBar = `分类：${obj.name}`;
      variables = {page: _page, category: _name};
      pagePrefix = `/category/${_name}/<PAGE>/`;
      title = `分类 | ${obj.name} | ${base}`;
      break;

    case 'search':
      toolBar = `搜索：${_name}`;
      variables = {page: _page, search: _name};
      pagePrefix = `/search/${_name}/<PAGE>/`;
      title = `搜索 | ${_name} | ${base}`;
      break;

    default:
  }

  React.useEffect(() => {
    if (document) document.title = title;
  });

  return (
    <React.Fragment>
      {['index', 'tag', 'search', 'archive', 'category'].includes(comp) && (
        <Grid item xs={12}>
          <Query query={GET_ARTICLES} variables={variables}>
            {({data = {}}) => {
              const {articles, articles: {totalCount = 0} = {}} = data;
              const article =
                (articles &&
                  articles['edges'].map(({node}) => ({
                    ...node,
                    time: node['createdAt'],
                    tags: ((node['tags'] && node['tags']['edges']) || []).map(
                      ({node}) => node['name'],
                    ),
                  }))) ||
                [];
              const pageConfig = {
                allPage:
                  totalCount < 7
                    ? 0
                    : totalCount % 7 > 0
                    ? parseInt(String(totalCount / 7 + 1))
                    : totalCount / 7,
                currentPage: _page,
                linkPrefix: pagePrefix,
              };

              return (
                <React.Fragment>
                  <Hidden smDown implementation={'css'}>
                    <Grid container spacing={0} className={classes.body}>
                      <Grid item xs={8}>
                        <Sections
                          toolBar={toolBar}
                          articles={article}
                          page={pageConfig}
                        />
                      </Grid>
                      <Grid item xs={4} children={<Aside />} />
                    </Grid>
                  </Hidden>
                  <Hidden mdUp implementation={'css'}>
                    <Grid container spacing={0} className={classes.body}>
                      <Grid item xs={12}>
                        <Sections
                          toolBar={toolBar}
                          articles={article}
                          page={pageConfig}
                        />
                      </Grid>
                    </Grid>
                  </Hidden>
                </React.Fragment>
              );
            }}
          </Query>
        </Grid>
      )}

      {['about', 'article'].includes(comp) && (
        <Query
          query={{about: GET_ABOUT, article: GET_ARTICLE}[comp]}
          variables={{about: {}, article: {url}}[comp]}
        >
          {({data}) => {
            const Content = () => (
              <div
                className='pg-markdown-preview'
                dangerouslySetInnerHTML={{
                  __html: {
                    about:
                      data &&
                      data['about'] &&
                      data['about']['nodes'] &&
                      data['about']['nodes'][0] &&
                      data['about']['nodes'][0]['value'],
                    article:
                      data &&
                      data['article'] &&
                      data['article']['nodes'] &&
                      data['article']['nodes'][0] &&
                      data['article']['nodes'][0]['rendered'],
                  }[comp],
                }}
              />
            );

            return (
              <Container className={classes.body} disableGutters>
                <Hidden smDown implementation={'css'}>
                  <Paper elevation={0} className={classes.paper}>
                    <Content />
                  </Paper>
                </Hidden>
                <Hidden mdUp implementation={'css'}>
                  <Paper
                    elevation={0}
                    className={classes.paper}
                    style={{margin: 0, padding: 0}}
                  >
                    <Content />
                  </Paper>
                </Hidden>
                <NoSsr>
                  {data && data['article'] && (
                    <Container
                      style={{marginTop: 24}}
                      disableGutters={['xs', 'sm'].includes(width)}
                    >
                      <Comment
                        article={
                          data &&
                          data['article'] &&
                          data['article']['nodes'] &&
                          data['article']['nodes'][0] &&
                          data['article']['nodes'][0]['id']
                        }
                      />
                      <Donate />
                    </Container>
                  )}
                </NoSsr>
              </Container>
            );
          }}
        </Query>
      )}
    </React.Fragment>
  );
}

export default compose(withApollo, graphql(GET_CACHE))(Body);
