const defaults = {
  theme: 'light',
};

const resolvers = {};

const typeDefs = `
  type Query {
    theme: String
  }
`;

export {defaults, resolvers, typeDefs};
