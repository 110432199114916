import React from 'react';
import classNames from 'classnames';
import {Query} from 'react-apollo';
import {makeStyles} from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Donate from '../body/donate';
import {footerColor, footerBackground} from '../../theme';
import {GET_LINKS} from '../../data/sql';

export default function Footer() {
  const classes = makeStyles((theme) => ({
    footer: {
      padding: '36px 0',
      backgroundColor: footerBackground,
    },
    font: {
      color: footerColor,
      '& a': {
        color: footerColor,
      },
    },
    title: {
      marginBottom: 36,
      fontSize: theme.typography.pxToRem(21),
      fontWeight: 300,
    },
    linkBox: {
      marginBottom: 24,
      fontWeight: 300,
    },
    link: {
      '& > div > div': {
        marginBottom: 16,
        display: 'block',
      },
    },
    copyright: {
      '& a': {
        marginBottom: 16,
        width: '100%',
        fontWeight: 300,
        display: 'block',
      },
      '& a:last-child': {
        marginBottom: 0,
      },
    },
    mobile: {
      lineHeight: '1.7rem',
    },
  }))();
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow((prevState) => !prevState);

  return (
    <Grid item xs={12}>
      <Donate show={show} toggleShow={toggleShow} />

      <Grid container spacing={0} justify='center' className={classes.footer}>
        <Grid item xs={9}>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              className={classNames(classes.font, classes.title)}
              children={'兵团基地'}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container className={classNames(classes.link, classes.font)}>
              <Grid item xs={12} sm={4} className={classes.linkBox}>
                <Grid
                  className={classes.link}
                  children={
                    <a
                      href='https://www.pulsgarney.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      总站
                    </a>
                  }
                />
                <Grid
                  className={classes.link}
                  children={
                    <a
                      href='mailto:admin@pulsgarney.com'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      联系站长
                    </a>
                  }
                />
                <Grid
                  className={classes.link}
                  children={
                    <span onClick={toggleShow} style={{cursor: 'pointer'}}>
                      支持我
                    </span>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} className={classes.linkBox}>
                <Grid
                  className={classes.link}
                  children={
                    <a
                      href='https://github.com/PulsGarney'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      代码仓库
                    </a>
                  }
                />
                <Grid
                  className={classes.link}
                  children={
                    <a
                      href='https://music.pulsgarney.com/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      音乐主页
                    </a>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4} className={classes.linkBox}>
                <Grid
                  className={classes.link}
                  children={<span>友情链接</span>}
                />
                <Query query={GET_LINKS} fetchPolicy='cache-only'>
                  {({
                    data: {
                      links: {nodes: [{value = '[]'} = {}] = []} = {},
                    } = {},
                  }) => {
                    try {
                      if (value) value = JSON.parse(value);
                    } catch (e) {
                      console.error(e);
                    }

                    if (!(value && value.length)) return null;

                    return value.map((x) => (
                      <Grid
                        key={x.link}
                        className={classes.link}
                        children={
                          <a
                            href={x.link || ''}
                            target='_blank'
                            rel='noopener noreferrer'
                            children={x.name || ''}
                          />
                        }
                      />
                    ));
                  }}
                </Query>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              className={classNames(classes.font, classes.copyright)}
            >
              <Hidden smDown implementation={'css'}>
                <span>
                  Power by React, GraphQL | Design by Puls Garney | Version:
                  {' ' + (process.env['REACT_APP_VERSION'] || '')}
                </span>
                <br />
                <span>
                  Copyright © 2017-{new Date().getFullYear()} Puls Garney - All
                  Rights Reserved.
                </span>
              </Hidden>
              <Hidden mdUp implementation={'css'}>
                <span className={classes.mobile}>
                  Power by React, GraphQL
                  <br />
                  Design by Puls Garney
                  <br />
                  Version: {process.env['REACT_APP_VERSION'] || ''}
                </span>
                <br />
                <span className={classes.mobile}>
                  Copyright © 2017-{new Date().getFullYear()} Puls Garney
                  <br />
                  All Rights Reserved.
                </span>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
